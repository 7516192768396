.disc {
    list-style: disc !important;
}

.number {
    list-style: decimal !important;
}

.listWrap {
    word-break: break-word;
    white-space: pre-wrap;
}

.listItem {
    line-height: 1.6em;
    display: flex;
    margin: 2px 0;
}

.listItem_body {
    flex-grow: 2;
}

/* .ce_block {
    width: 80%;
    margin: 0 auto;
} */